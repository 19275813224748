<template>
	<div class="page">
		<div class="service_banner relative">
			<img class="img" :src="bannerObj.imageUrl || require('../assets/static/mine_bg.png')"/>
			<div class="title">
				<div class="fontsize48 colorwhite">{{bannerObj.title || ""}}</div>
				<div class="desc fontsize18 colorwhite">{{bannerObj.subtitle || ""}}</div>
			</div>
		</div>
		<div class="block20"></div>
		<div class="huaxue_mine_box w1239 relative">
			<div class="huaxue_mine_type">
				<div class="type_item fontsize18" :class="{'hover':mineType==0}" @click="changeType(0)">简介</div>
				<div class="type_item fontsize18" :class="{'hover':mineType==1}" @click="changeType(1)">服务介绍</div>
				<div class="type_item fontsize18" :class="{'hover':mineType==2}" @click="changeType(2)">服务需求</div>
				<div class="type_item fontsize18" :class="{'hover':mineType==3}" @click="changeType(3)">联系我们</div>
			</div>
			<div class="jianjie_box" v-show="mineType==0">
				<div class="about_mine" v-if="aboutYunying&&aboutYunying.state==0">
					<div class="title fontsize24">{{aboutYunying.title || "关于华学知识产权运营平台"}}</div>
					<div class="desc fontsize14" v-html="aboutYunying.contents"></div>
				</div>
				<div class="about_huaxue">
					<div class="left" v-if="aboutHuaxue&&aboutHuaxue.state==0">
						<div class="title fontsize24">{{aboutHuaxue.title || "关于华学"}}</div>
						<div class="title_en fontsize14">ABOUT HUAXUE</div>
						<div class="desc fontsize14" v-html="aboutHuaxue.contents"></div>
					</div>
					<!-- <div class="right">
						<div class="swiper relative">
						    <swiper ref="mySwiper1" :options="swiperOptions1">
						        <swiper-slide
						            class="swiperslide"
						            v-for="(item,index) in bannerList"
						            :key="index">
									<img class="img" :src="item.imageUrl" />
						        	<div class="swiper-text fontsize14 clamp">{{item.title || ""}}</div>
						        </swiper-slide>
						    </swiper>
							<div class="prev fontsize14" v-html="'<'" @click="slidePrev1"></div>
							<div class="next fontsize14" v-html="'>'" @click="slideNext1"></div>
						</div>
					</div> -->
				</div>
				<div class="huaxue_team" v-if="teamList.length>0">
					<div class="title fontsize24">精英团队</div>
					<div class="title_en fontsize14">TEAM</div>
					<div class="swiper relative" v-if="swiperOptions2.isshow">
						<swiper ref="mySwiper2" :options="swiperOptions2">
						    <swiper-slide
						        class="swiperslide"
						        v-for="(item,index) in teamList"
						        :key="index">
								<div class="slide_item pointer" @click="$util.routerPath('/teamDetail',{uid:item.id})">
									<div class="team_item">
										<div class="img">
											<img :src="item.imgUrl || require('../assets/static/logo.png')"/>
										</div>
										<div class="team_con">
											<div class="title_ch fontsize18">{{item.title}}<span class="fontsize14">{{item.description || ""}}</span></div>
											<!-- <div class="title_en fontsize18">SuYunZhen</div> -->
											<div class="desc fontsize14" v-html="item.contents || '暂无相关介绍'"></div>
										</div>
									</div>
								</div>
						    </swiper-slide>
						</swiper>
						<div class="prev fontsize14" v-html="'<'" @click="slidePrev2"></div>
						<div class="next fontsize14" v-html="'>'" @click="slideNext2"></div>
					</div>
				</div>
			</div>
			<div class="introduce_box" v-show="mineType==1">
				<!-- <div class="con_desc fontsize14">成立以来，秉承华南理工大学专利事务所的优良传统，以服务高校及科研院所为主要的任务，是唯一注册在高校内的代理机构，代理了高校及科研院所大量的高难度专利申请，其中不少涉及国家重大科研项目、自然科学基金项目等，专业面涉及机械、电子、通信、物理、化工、医药、食品、生物工程等各种技术领域，形成了专业服务高端客户的特色。除此之外，我们亦凭借高质量的代理水平为公司、企事业单位（其中大部分是高新技术企业、单位）、个人等代理了大量质优、实用的专利申请，赢得了“优质、高效”的服务口碑。</div>
				<div class="con_title fontsize24">代理<span>服务</span></div>
				<div class="datalist">
					<div class="data_item">
						<div class="title bgblue fontsize20">国内专利</div>
						<div class="data_con fontsize14">
							<div class="text">1.专利咨询、申请、复审、无效</div>
                            <div class="text">2.专利复审、无效行政诉</div>
                            <div class="text">3.讼专利侵权分析、纠纷专利新颖性、法律状态、技术主题检索</div>
                            <div class="text">4.专利许可、转让</div>
                            <div class="text">5.专利许可合同、质押合同备案</div>
                            <div class="text">6.专利年费缴纳</div>
                            <div class="text">7.专利权利恢复</div>
                            <div class="text">8.专利法律顾问</div>
						</div>
					</div>
					<div class="data_item">
						<div class="title bggreen fontsize20">涉外商标</div>
						<div class="data_con fontsize14">
							<div class="text">1.国外商标检索、检测、申请策略制定</div>
					        <div class="text">2.国外商标逐一注册申请</div>
					        <div class="text">3.国外商标续展、变更、转让许可备案</div>
					        <div class="text">4.国外商标驳回答辩、异议、异议答辩</div>
					        <div class="text">5.国外商标海关备案和侵权查处</div>
					        <div class="text">6.国外商标权纠纷的协商和诉讼</div>
					        <div class="text">7.港澳台商标注册申请</div>
						</div>
					</div>
					<div class="data_item">
						<div class="title bggreen fontsize20">涉外专利</div>
						<div class="data_con fontsize14">
							<div class="text">1.国外专利检索</div>
					        <div class="text">2.国外专利申请策略制定</div>
					        <div class="text">3.国外单一国家专利申请</div>
					        <div class="text">4.国外外观设计申请</div>
					        <div class="text">5.国外专利年费管理及缴纳</div>
					        <div class="text">6.国外专利无效宣告及答辩</div>
					        <div class="text">7.国外专利行政、侵权诉讼</div>
							<div class="text">8.PCT国际申请</div>
							<div class="text">9.港澳台专利申请</div>
						</div>
					</div>
					<div class="data_item">
						<div class="title bgblue fontsize20">国内商标</div>
						<div class="data_con fontsize14">
							<div class="text">1.国际注册、监测、检索、变更、转让、续展、许可、注销</div>
					        <div class="text">2.商标注册证补发变更、转让、续展证明</div>
					        <div class="text">3.讼专利侵权分析、纠纷专利新颖性、法律状态、技术主题检索</div>
					        <div class="text">4.商标驳回、异议、撤销复审</div>
					        <div class="text">5.商标无效宣告</div>
					        <div class="text">6.商标侵权纠纷</div>
					        <div class="text">7.商标确权行政诉讼</div>
					        <div class="text">8.省、市著名商标认定</div>
							<div class="text">9.驰名商标认定</div>
						</div>
					</div>
					<div class="data_item">
						<div class="title bgblue fontsize20">版权、域名</div>
						<div class="data_con fontsize14">
							<div class="text">1.版权法律咨询</div>
					        <div class="text">2.版权登记</div>
					        <div class="text">3.作品著作权登记申请</div>
					        <div class="text">4.计算机软件登记</div>
					        <div class="text">5.域名法律咨询</div>
					        <div class="text">6.域名注册申请、交易、转移</div>
					        <div class="text">7.域名争议仲裁与诉讼</div>
						</div>
					</div>
				</div> -->
				<!-- <div class="block40"></div> -->
				<div class="datalist2">
					<div class="data2_item">
						<div class="img"><img src="../assets/static/mine15.png" /></div>
						<div class="title fontsize20">知识产权法律</div>
						<div class="line"></div>
						<div class="data2_con fontsize14">
							<div class="text">· 知识产权诉讼</div>
							<div class="text">· 知识产权法律咨询</div>
							<div class="text">· 尽职尽责调查、证据收集</div>
							<div class="text">· 行政查处</div>
						</div>
					</div>
					<div class="data2_item ml20">
						<div class="img"><img src="../assets/static/mine11.png" /></div>
						<div class="title fontsize20">知识产权运营</div>
						<div class="line"></div>
						<div class="data2_con fontsize14">
							<div class="text">· 知识产权交易（转让）</div>
							<div class="text">· 知识产权许可</div>
							<div class="text">· 知识产权质押融资</div>
						</div>
					</div>
					<div class="data2_item ml20">
						<div class="img"><img src="../assets/static/mine14.png" /></div>
						<div class="title fontsize20">知识产权研究与培训</div>
						<div class="line"></div>
						<div class="data2_con fontsize14">
							<div class="text">· 知识产权宣传、教学和人才培训</div>
							<div class="text">· 企业内训</div>
							<div class="text">· 来访研修</div>
						</div>
					</div>
					<div class="data2_item">
						<div class="img"><img src="../assets/static/mine13.png" /></div>
						<div class="title fontsize20">知识产权战略及情报分析</div>
						<div class="line"></div>
						<div class="data2_con fontsize14">
							<div class="text">· 专利规避设计</div>
							<div class="text">· 专利挖掘与布局</div>
							<div class="text">· 专利稳定性分析</div>
							<div class="text">· 专利侵权分析</div>
							<div class="text">· 专利权评价报告</div>
							<div class="text">· 技术查新报告</div>
							<div class="text">· 专利价值评估</div>
							<div class="text">· 专利预警分析</div>
							<div class="text">· 专利地图分析</div>
							<div class="text">· 专利导航分析</div>
							<div class="text">· 高价值专利培育</div>
							<div class="text">· 重大知识产权分析评议</div>
							<div class="text">· 高价值专利培育布局</div>
							<div class="text">· FTO分析</div>
						</div>
					</div>
					<div class="data2_item ml20">
						<div class="img"><img src="../assets/static/mine12.png" /></div>
						<div class="title fontsize20">知识产权/科技项目申报服务</div>
						<div class="line"></div>
						<div class="data2_con fontsize14">
							<div class="text">· 高新技术企业认定</div>
							<div class="text">· 专利奖评选</div>
							<div class="text">· 企业研发机构建设申报</div>
							<div class="text">· 企业技术中心认定</div>
							<div class="text">· 工程技术研究中心认定</div>
							<div class="text">· 知识产权示范/优势企业认定</div>
							<div class="text">· 知识产权管理规范达标认证（即知识产权贯标）</div>
							<div class="text">· 知识产权托管</div>
							<div class="text">· 知识产权项目联合共同申报</div>
						</div>
					</div>
				</div>
			</div>
			<div class="mine_service_box" v-show="mineType==2">
				<div class="box_title fontsize30">服务需求</div>
				<div class="need_box">
					<div class="title fontsize18">需求类型</div>
					<div class="radiolist fontsize14">
						<el-radio-group v-model="formobj.demandType">
						    <el-radio label="专利">专利</el-radio>
						    <el-radio label="商标">商标</el-radio>
						    <el-radio label="版权">版权</el-radio>
							<el-radio label="贯标">贯标</el-radio>
							<el-radio label="涉外">涉外</el-radio>
							<el-radio label="诉讼">诉讼</el-radio>
							<el-radio label="培训">培训</el-radio>
							<el-radio label="项目申报">项目申报</el-radio>
							<el-radio label="其他业务">其他业务</el-radio>
						</el-radio-group>
					</div>
				</div>
				<div class="inputdiv">
					<div class="title fontsize18">反馈内容</div>
					<div class="textarea fontsize14"><textarea placeholder="请输入反馈内容" maxlength="300" v-model="formobj.demandContent"></textarea></div>
				</div>
				<div class="inputform">
					<div class="title fontsize16">为保证您的建议得到及时处理，请填写真实姓名和有效联系方式，仅供回访使用</div>
					<div class="formlist">
						<div class="formobj">
							<div class="name">姓名</div>
							<div class="input"><input placeholder="请输入你的名字" v-model="formobj.userName"/></div>
						</div>
						<div class="formobj">
							<div class="name1">邮箱地址</div>
							<div class="input"><input placeholder="请输入你的邮箱地址" v-model="formobj.userEMail"/></div>
						</div>
					</div>
					<div class="formlist">
						<div class="formobj">
							<div class="name">手机号码</div>
							<div class="input"><input placeholder="请输入你的手机号码" type="number" v-model="formobj.userPhone"/></div>
						</div>
					</div>
					<div class="formbtn fontsize16" @click="addNeedEntity">提交</div>
				</div>
			</div>
			<div class="map_box" v-show="mineType==3">
				<div class="map fontsize12" id="map"></div>
				<div class="companyinfo">
					<div class="titlename fontsize18 relative">广州华知智创知识产权运营有限公司</div>
					<div class="info1 fontsize14">
						<div class="text1">公司地址：<span>广州市天河区金颖路1号金颖大厦16楼</span></div>
						<div class="text1">邮   编：<span>510640</span></div>
						<div class="text1">联系电话：<span>020-38550992；38550875；38550767</span></div>
						<!-- <div class="text1">传   真：<span>总机转8002</span></div> -->
						<div class="text1">电子邮箱： <span>huaxue@chinahuaxue.com</span></div>
						<div class="text1">涉外邮箱：<span>info@chinahuaxue.com</span></div>
						<div class="text1">客服邮箱：<span>support@huaxueip.com</span></div>
						<div class="text1">公司网址：<span>www.chinahuaxue.com</span></div>
					</div>
					<!-- <div class="info2 fontsize14">
						<div class="text1">交通方式：</div>
						<div class="text2">地铁：3号线石牌桥站、5号线猎德站、18号线冼村站</div>
						<div class="text2">公交：天河南站、猎德大道北站、冼村站</div>
					</div> -->
					<!-- <div class="info2 fontsize14">
						<div class="text1">邮编： <span>510640</span></div>
						<div class="text1">联系电话：<span>020-38550992；38550875；38550767</span></div>
					</div> -->
				</div>
			</div>
		
		</div>
		<div class="block20"></div>
	</div>
</template>

<script>
var map;
import {mapState} from 'vuex'
//导入swiper
import 'swiper/dist/css/swiper.css'
import { swiper, swiperSlide } from 'vue-awesome-swiper'
export default {
	components: {
	    swiper,
	    swiperSlide
	},
	data() {
		return {
			mineType:0,//简介、服务介绍，服务需求，联系我们
			swiperOptions1:{
				loop:true,
				autoplay: {
				    delay: 3000, //自动切换的时间间隔，单位ms
				},
			},
			swiperOptions2:{
				isshow:false,
				slidesPerView: 4,
			},
			bannerList: [
				{
					imageUrl:require("../assets/static/mine_bg.png"),
					title:"依托实体，专业托管",
				}
			],
			aboutHuaxue:{
				title: "关于华学",
				contents:"",
			},
			aboutYunying:{
				title: "关于华学知识产权运营平台",
				contents:"",
			},
			teamList:[],//精英团队
			formobj:{
				demandType:"专利",//下标
				demandContent:null,
				userEMail:null,
				userName:null,
				userPhone:null,
			},
			bannerObj:{}
		};
	},
	mounted: function() {
		//头图
		this.getBannerFuc(21).then(bannerObj => {
			//console.log(bannerObj.imageUrl)
			if(bannerObj&&bannerObj.imageUrl){
				this.bannerObj = bannerObj
			}
		});
		if(document.body.clientWidth<750){
			this.swiperOptions2.slidesPerView = 1
			// console.log(this.swiperOptions2.slidesPerView)
		}
		if(this.$route.query&&this.$route.query.type){
			this.mineType = parseInt(this.$route.query.type)
		}
		this.getwheelPage()
		this.getGuanyuhuaxue()
		this.getyunyingpingtai()
		//this.getjingyingtuandui()
		this.initMap()
	},
	computed: {
		...mapState(['userInfo', 'isLogin']),
	},
	methods: {
		//获取轮播图
		getwheelPage() {
			var _this = this;
			var params = {
				currentPage: 1,
				pageSize: 8,
				type:1,//关于化学轮播图
			};
			this.$http.post('frontEnd/index/getWheel', params).then(function(res) {
				//数据处理
				if (res.code == 200) {
					var records = res.data.records;
					if(records.length>0){
						_this.bannerList = records
					}
				} else {
					_this.$confirm(res.message, '提示', {
						confirmButtonText: '确定',
						cancelButtonText: '取消',
						type: 'warning'
					}).then(() => {}).catch(() => {});
				}
			})
		},
		//关于华学
		getGuanyuhuaxue() {
			var _this = this;
			var params = {};
			this.$http.post('frontEnd/aboutUs/getGuanyuhuaxue', params).then(function(res) {
				//数据处理
				if (res.code == 200) {
					if(res.data){
						_this.aboutHuaxue = res.data
					}
				} else {
					_this.$confirm(res.message, '提示', {
						confirmButtonText: '确定',
						cancelButtonText: '取消',
						type: 'warning'
					}).then(() => {}).catch(() => {});
				}
			})
		},
		//关于华学知识产权运营平台
		getyunyingpingtai() {
			var _this = this;
			var params = {};
			this.$http.post('frontEnd/aboutUs/getyunyingpingtai', params).then(function(res) {
				//数据处理
				if (res.code == 200) {
					if(res.data){
						_this.aboutYunying = res.data
					}
				} else {
					_this.$confirm(res.message, '提示', {
						confirmButtonText: '确定',
						cancelButtonText: '取消',
						type: 'warning'
					}).then(() => {}).catch(() => {});
				}
			})
		},
		//获精英团队
		getjingyingtuandui() {
			var _this = this;
			var params = {
				currentPage: 1,
				pageSize: 88,
			};
			this.$http.post('frontEnd/aboutUs/getjingyingtuandui', params).then(function(res) {
				//数据处理
				if (res.code == 200) {
					var records = res.data.records;
					if(records.length>0){
						_this.teamList = records
					}
					//展示
					_this.swiperOptions2.isshow = true
				} else {
					_this.$confirm(res.message, '提示', {
						confirmButtonText: '确定',
						cancelButtonText: '取消',
						type: 'warning'
					}).then(() => {}).catch(() => {});
				}
			})
		},
		//获取详情
		addNeedEntity() {
			var _this = this;
			var params = this.formobj
			if(!params.userName){
				this.$message.error("请输入名字")
				return;
			}
			var re_phone = /^1(3|4|5|6|7|8|9)\d{9}$/;
			if(!re_phone.test(params.userPhone)){
				this.$message.error("请输入正确的联系方式")
				return;
			}
			//console.log(params)
			this.$http.post('manager/service-demand/addEntity', params).then(function(res) {
				//数据处理
				if (res.code == 200) {
					alert("提交成功，请等待客服与您联系")
					_this.formobj = {
						demandType:"专利",//下标
						demandContent:null,
						userEMail:null,
						userName:null,
						userPhone:null
			        }
				} else {
					_this.$confirm(res.message, '提示', {
						confirmButtonText: '确定',
						cancelButtonText: '取消',
						type: 'warning'
					}).then(() => {}).catch(() => {});
				}
			})
		},
		//上一页
		slidePrev1() {
		    this.$refs.mySwiper1.swiper.slidePrev();
		},
		//下一页
		slideNext1() {
		    this.$refs.mySwiper1.swiper.slideNext()
		},
		//上一页
		slidePrev2() {
		    this.$refs.mySwiper2.swiper.slidePrev();
		},
		//下一页
		slideNext2() {
		    this.$refs.mySwiper2.swiper.slideNext()
		},
		//切换栏目
		changeType(type){
			this.mineType = type
		},
		//创建和初始化地图函数：
		initMap(){
		    this.createMap();//创建地图
		},
		createMap(){ 
		   map = new BMap.Map("map");
		   map.centerAndZoom(new BMap.Point(113.341043,23.131582),17);
		   this.setMapEvent();//设置地图事件
		   this.addMapControl();//向地图添加控件
		   this.addMapOverlay();////向地图添加覆盖物
		},
		setMapEvent(){
		    map.enableScrollWheelZoom();
		    map.enableKeyboard();
		    map.enableDragging();
		    map.enableDoubleClickZoom()
		},
		addClickHandler(target,window){
		    target.addEventListener("click",function(){
		      target.openInfoWindow(window);
		    });
		},
		addMapOverlay(){
		    var markers = [
		      {content:"广州市天河区金颖路1号金颖大厦16楼",title:"我的位置",imageOffset: {width:0,height:3},position:{lat:23.146212,lng:113.347351}}
		    ];
		    for(var index = 0; index < markers.length; index++ ){
		        var point = new BMap.Point(markers[index].position.lng,markers[index].position.lat);
		        var marker = new BMap.Marker(point,{icon:new BMap.Icon("http://api.map.baidu.com/lbsapi/createmap/images/icon.png",new BMap.Size(20,25),{
		          imageOffset: new BMap.Size(markers[index].imageOffset.width,markers[index].imageOffset.height)
		        })});
		        var label = new BMap.Label(markers[index].title,{offset: new BMap.Size(25,5)});
		        var opts = {
		          width: 200,
		          title: markers[index].title,
		          enableMessage: false
		        };
		        var infoWindow = new BMap.InfoWindow(markers[index].content,opts);
		        marker.setLabel(label);
		        this.addClickHandler(marker,infoWindow);
		        map.addOverlay(marker);
		    }
		},
		//向地图添加控件
		addMapControl(){
		    var scaleControl = new BMap.ScaleControl({anchor:BMAP_ANCHOR_BOTTOM_LEFT});
		    scaleControl.setUnit(BMAP_UNIT_IMPERIAL);
		    map.addControl(scaleControl);
		    var navControl = new BMap.NavigationControl({anchor:BMAP_ANCHOR_TOP_RIGHT,type:BMAP_NAVIGATION_CONTROL_LARGE});
		    map.addControl(navControl);
		    var overviewControl = new BMap.OverviewMapControl({anchor:BMAP_ANCHOR_BOTTOM_RIGHT,isOpen:true});
		    map.addControl(overviewControl);
		},
		
	}
};
</script>
<style lang="scss">
// 控制文字
.BMap_cpyCtrl {
   display: none !important;
}
//控制图标
.anchorBL{
   display: none !important;
}
</style>
